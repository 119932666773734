// external imports
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import moment from 'moment'
import styled, { css } from 'styled-components'

// helpers
import { up, addResponsivity } from '../lib/styles'
import withExtraction from '../lib/withExtraction'
import injectIntl from '../lib/intl/injectIntl'

// components
import SEO from '../components/SEO'
import { Page, PageContent } from '../components/Page'
import {
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Button,
  Card,
  Testimonials,
  Line,
  SubHeaderBar,
} from '../components'
import ClosestCoursesBox from '../components/ClosestCoursesBox'
import pagesConfig from '../../pagesConfig'
import { categoriesKeysObject } from '../courses'
import homepageTopSrc from '../data/images/homepage-dots.png'
import homepageMobileTopSrc from '../data/images/homepage-dots-mobile.png'
import T from '../lib/intl/T'
import IntroVideo from '../components/IntroVideo'

const topImageBp = 'mobile'
const topButtonsBp = 460

const TopImageWrapper = styled.div`
  ${() => css`
    display: none;

    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);

    height: 100%;
    max-width: 45%;
    padding: 25px 0 115px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    pointer-events: none;
    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
    }

    ${up(topImageBp)} {
      display: flex;
    }
    ${up('mobile')} {
      right: -45px;
      padding: 20px 0 50px;
      max-width: 50%;
    }
    }
    ${up(1400)} {
      right: 100px;
      padding: 50px 0 100px;
    }
  `}
`

const TomImageMobileWrapper = styled.div`
  display: block;
  img {
    display: block;
    max-width: 100%;
  }
  ${up(topImageBp)} {
    display: none;
  }
`

const MainTitle = styled(Title.Page)`
  max-width: 290px;
  ${up('mobile')} {
    max-width: 670px;
  }
  ${up(1000)} {
    max-width: 820px;
  }
`

class Index extends React.Component {
  componentDidMount() {}

  render() {
    const { language, t } = this.props.intl

    const { allPosts, allCourseTerms } = this.props.data

    const lastPost = allPosts[0]

    const itemsToList = allCourseTerms
      .filter(term => term.isPublished)
      .filter(term => moment(term.from, 'YYYY-MM-DD').isAfter(moment()))
      .slice(0, 5)

    const title = t('ATC metod pro osobní rozvoj')

    // The Accredited Training Center of personal development methods

    return (
      <>
        <SEO title=" " titleTemplate={title} />

        {/* <IntroVideo /> */}

        <Page pink>
          <PageContent>
            <Gap gap="164px" mobileGap="50px" />
            <Row responsive bp={680}>
              <MainTitle color="green" maxWidth="820px" mobileMaxWidth="290px">
                <T>
                  Zjistěte na našich kurzech vše o metodách prof. Feuersteina
                </T>
              </MainTitle>
              <Gap gap="46px" />
              <TomImageMobileWrapper>
                <img src={homepageMobileTopSrc} alt="" />
              </TomImageMobileWrapper>
            </Row>
            <Gap gap="46px" mobileGap="32px" />
            <Row
              responsive
              bp={topButtonsBp}
              maxWidth="500px"
              mobileMaxWidth="440px"
            >
              <Button.PrimaryOrange
                mobilePaddingLeft="12px"
                mobilePaddingRight="12px"
                link={{ pageKey: Link.COURSES }}
                responsiveFullWidth
              >
                <T>nabízené KURZY</T>
              </Button.PrimaryOrange>
              <Gap gap="18px" />
              <Button.SecondaryOrange
                mobilePaddingLeft="10px"
                mobilePaddingRight="12px"
                iconLeft="trolley"
                link={{
                  target: '_blank',
                  asA: true,
                  href: 'https://centrumrozum.cz/vzdelavaci-materialy/objednavka-miu/',
                }}
                responsiveFullWidth
              >
                <T>instrumenty</T>
              </Button.SecondaryOrange>
              <TopImageWrapper>
                <img src={homepageTopSrc} alt="" />
              </TopImageWrapper>
            </Row>
            <Gap gap="186px" mobileGap="52px" />
          </PageContent>
        </Page>

        <Page bg="greenGradient">
          <PageContent>
            <Gap gap="58px" mobileGap="48px" />
            <SubHeaderBar.Content maxWidth="1159px">
              <Text color="white" letterSpacing="0.75px" style={{ flexBasis: '53%' }}>
                <T raw>
                  {
                    '<strong>Autorizované tréninkové centrum</strong> metod profesora Reuvena Feursteina šíří jeho metody v českém a slovenském prostředí a slouží jako maják všem, kteří se metodami zaobírají. Všechny naše kurzy jsou akreditovány na MŠMT České republiky.'
                  }
                </T>
              </Text>
              <Gap gap="85px" mobileGap="48px" />
              <Text color="white" letterSpacing="0.75px" style={{ flexBasis: '47%' }}>
                <T raw>
                  {
                    '<strong>Feuersteinovy metody</strong> jsou zaměřeny na rozvoj umění učit se. Umožňují zjišťovat a rozvíjet učební potenciál jedince. Vychází z principů strukturální kognitivní modifikovatelnosti a zkušenosti zprostředkovaného učení.'
                  }
                </T>
              </Text>
            </SubHeaderBar.Content>
            <Gap gap="82px" mobileGap="0" bp="desktop" />
          </PageContent>

          <PageContent position="relative">
            <Gap gap="174px" hideBelow="desktop" />
            <Gap gap="70px" mobileGap="48px" />
            <Card.Grid desktopPosition="absolute">
              <Card orange>
                <Card.Header>
                  <T raw>{'Metodu neznám,<br />chci se o ní dozvědět'}</T>
                </Card.Header>
                <Card.Content>
                  <Card.SquareIcon />
                  <Card.Text>
                    <T>Více informací o prof. Reuvenu Feuersteinovi a</T>{' '}
                    <Link.TextLink pageKey={Link.METHODS}>
                      <T>jeho metodách.</T>
                    </Link.TextLink>{' '}
                    <Card.TextGap />
                    <T>Nabízíme zážitkovou cestu přes naše</T>{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.TASTING }}
                    >
                      <T>„ochutnávky“</T>
                    </Link.TextLink>{' '}
                    <T>nebo</T>{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.CONFERENCE }}
                    >
                      <T>konference.</T>
                    </Link.TextLink>
                  </Card.Text>
                  <Card.Button link={{ pageKey: Link.METHODS }}>
                    <T>více o metodách</T>
                  </Card.Button>
                </Card.Content>
              </Card>
              <Card orange>
                <Card.Header>
                  <T raw>Metodu (trochu) znám, chci si najít lektora</T>
                </Card.Header>
                <Card.Content>
                  <Card.FindPersonIcon />
                  <Card.Text>
                    <T>Vyzkoušejte naši</T>{' '}
                    <Link.TextLink
                      pageKey={Link.COLLABORATORS}
                      anchor={Link.anchors.COLLABORATORS_MAP}
                    >
                      <T>mapu lektorů a škol.</T>
                    </Link.TextLink>
                    <Card.TextGap />
                    <T>Snadno tak najdete lektory a školy ve vašem okolí.</T>
                  </Card.Text>
                  <Card.Button link={{ pageKey: Link.COLLABORATORS }}>
                    <T>najít lektora</T>
                  </Card.Button>
                </Card.Content>
              </Card>
              <Card orange>
                <Card.Header>
                  <T raw>{'chci se stát   <br />lektorem'}</T>
                </Card.Header>
                <Card.Content>
                  <Card.HatIcon />
                  <Card.Text>
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.FIE_STANDARD }}
                    >
                      <T>Přihlašte se</T>
                    </Link.TextLink>{' '}
                    <T>na kurz pro lektory</T>{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.FIE_STANDARD }}
                    >
                      <T>FIE Standard I.</T>
                    </Link.TextLink>
                    <Card.TextGap />
                    <T>
                      Pokud váháte a chcete si metodu vyzkoušet, doporučujeme
                    </T>{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.TASTING }}
                    >
                      <T>„ochutnávku“.</T>
                    </Link.TextLink>
                  </Card.Text>
                  <Card.Button link={{ pageKey: Link.JOURNEY }}>
                    <T>cesta lektora</T>
                  </Card.Button>
                </Card.Content>
              </Card>
              <Card orange>
                <Card.Header>
                  <T raw>jsem lektor a chci se dál rozvíjet</T>
                </Card.Header>
                <Card.Content>
                  <Card.PencilIcon />
                  <Card.Text>
                    <T>Lektorům nabízíme další kurzy FIE (řady</T>
                    {' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.FIE_STANDARD }}
                    >
                      <T>Standard</T>
                    </Link.TextLink>{' '}
                    <T>a</T>{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.FIE_BASIC }}
                    >
                      <T>Basic</T>.
                    </Link.TextLink>
                    <Card.TextGap />
                    <T>Pořádáme</T>{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.TASTING }}
                    >
                      <T>inspirační semináře</T>
                    </Link.TextLink>{' '}
                    <T>nebo</T>{' '}
                    <Link.TextLink
                      pageKey={Link.COURSES}
                      anchor={Link.anchors.COURSES_BOX}
                      params={{ active: categoriesKeysObject.CONFERENCE }}
                    >
                      <T>konference.</T>
                    </Link.TextLink>
                  </Card.Text>
                  <Card.Button link={{ pageKey: Link.JOURNEY }}>
                    <T>cesta lektora</T>
                  </Card.Button>
                </Card.Content>
              </Card>
            </Card.Grid>
            <Gap gap="0" mobileGap="80px" bp="desktop" />
          </PageContent>
        </Page>

        <Page bg="bgGrey">
          <Gap gap="420px" showAfter="desktop" />
          <PageContent>
            <Line />
            <Gap gap="120px" mobileGap="60px" />
            <Row
              width="100%"
              justifyContent="center"
              alignItems="center"
              responsive
            >
              <Col width="100%" mobileMaxWidth="397px" maxWidth="517px">
                <RH hideBelow="mobile">
                  <Link.Simple pageKey={Link.POST} slugs={lastPost.slugs}>
                    <Img
                      fluid={
                        lastPost.image.file && lastPost.image.file.imgHp.fluid
                      }
                    />
                  </Link.Simple>
                </RH>
              </Col>
              <Gap gap="84px" hideBelow="desktop" />
              <Gap gap="60px" mobileGap="0" />
              <Col
                alignItems="flex-start"
                maxWidth="100%"
                mobileMaxWidth="397px"
              >
                <Title.MiniOrange>
                  <T>Aktuálně z ATC</T>
                </Title.MiniOrange>
                <Col width="100%">
                  <RH hideAfter="mobile">
                    <Gap gap="20px" />
                    <Link.Simple pageKey={Link.POST} slugs={lastPost.slugs}>
                      <Img
                        fluid={
                          lastPost.image.file && lastPost.image.file.img.fluid
                        }
                      />
                    </Link.Simple>
                  </RH>
                </Col>
                <Gap gap="24px" mobileGap="20px" />
                <Link.Simple pageKey={Link.POST} slugs={lastPost.slugs}>
                  <Title.Section>{lastPost.title}</Title.Section>
                </Link.Simple>
                <Gap gap="40px" mobileGap="20px" />
                <Text.Wide>{lastPost.perex}</Text.Wide>
                <Gap gap="24px" />
                <Col
                  alignItems="flex-start"
                  mobileAlignItems="center"
                  width="100%"
                >
                  <Link.OrangeLink pageKey={Link.POST} slugs={lastPost.slugs}>
                    » <T>ČÍST DÁL</T>
                  </Link.OrangeLink>
                  <Gap gap="58px" mobileGap="30px" />
                  <Button.PrimaryOrange link={{ pageKey: Link.BLOG }}>
                    <T>další aktuality</T>
                  </Button.PrimaryOrange>
                </Col>
              </Col>
            </Row>
            <Gap gap="120px" mobileGap="60px" />
            <Line />
            <Gap gap="120px" mobileGap="60px" />

            <Col width="100%" alignItems="stretch">
              <ClosestCoursesBox
                items={itemsToList}
                title="Nejbližší nabízené kurzy a akce"
                link={{ pageKey: Link.COURSES }}
              />
            </Col>
            <Gap gap="80px" mobileGap="50px" />
          </PageContent>
        </Page>
        <Gap gap="192px" mobileGap="62px" />
        <Page>
          <PageContent>
            <Testimonials />
          </PageContent>
        </Page>
        <Gap gap="198px" mobileGap="97px" />
      </>
    )
  }
}

export default injectIntl(withExtraction(Index))

export const pageQuery = graphql`
  query {
    ks {
      allPosts(where: { isPublished: true }, sortBy: publishedAt_DESC) {
        ...PostFragment
      }
      allCourseTerms(sortBy: from_ASC) {
        ...CourseTermFragment
        ...CourseTermNestedFragment
      }
    }
  }
`

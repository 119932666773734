/* eslint-disable react/no-danger */
import Select from 'react-select'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import Title from './Title'
import Button from './Button'
import ListingCourses from './ListingCourses'
import { Line, RH } from '.'
import { Page, PageContent } from './Page'
import T from '../lib/intl/T'
import { up, addPagePadding } from '../lib/styles'
import pagesConfig from '../../pagesConfig'
import injectIntl from '../lib/intl/injectIntl'
import { categories, getCategoriesKeys } from '../courses'

const ListingWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    align-items: flex-start;
    box-shadow: 6px 10px 81px rgba(0, 0, 0, 0.1);

    padding: 0 20px;
    ${up('mobile')} {
      padding: 0 90px;
    }
  `}
`

// eslint-disable-next-line react/prefer-stateless-function
class ClosestCoursesBox extends React.Component {
  render() {
    // courses grouped by tab key
    const { items, title, link } = this.props

    // console.log('items', items)

    return (
      <>
        <Col>
          <ListingWrapper>
            <Gap gap="66px" mobileGap="32px" />
            <Title.Section
              textAlign="center"
              alignSelf="center"
              color="#284c36"
            >
              {title}
            </Title.Section>

            <Gap gap="90px" desktop />

            <Col width="100%" alignItems="stretch">
              <ListingCourses
                items={items}
                withoutTags
                withoutFirstLine
                withOrangeButton
              />
            </Col>

            <Gap gap="60px" mobileGap="30px" />

            <Col alignSelf="center">
              <Button.PrimaryOrange
                // iconLeft="arrowDown"
                link={link}
              >
                Všechny Kurzy
              </Button.PrimaryOrange>
            </Col>

            <Gap gap="68px" mobileGap="35px" />
          </ListingWrapper>
        </Col>
      </>
    )
  }
}

ClosestCoursesBox.defaultProps = {}

export default ClosestCoursesBox
